import type { Getter } from '@core-types/utility'
import scssVariables from '../assets/scss/abstract/_variables-export.module.scss'
import type { ScssBreakpoint } from '@core-types/scss'

export const scssBreakpoint = scssVariables as unknown as Record<Uppercase<ScssBreakpoint>, string>

export function getValueByGetter<T>(
    source: T,
    getter: Getter<T> | undefined | null
): T[keyof T] | null {
    if (!getter || typeof source !== 'object' || !source) {
        return null
    }

    if (typeof getter === 'function') {
        return getter(source)
    }

    if (!isKeyInObject(getter, source)) {
        if (typeof getter !== 'string') {
            return null
        }

        const paths = getter.split('.')
        let value = source

        for (const path of paths) {
            if (!isKeyInObject(path, value)) {
                return null
            }

            // @ts-ignore
            value = value[path]
        }

        // @ts-ignore
        return value
    }

    return source[getter]
}

export function isKeyInObject<T extends object>(key: string | number | symbol | keyof T, object: T): key is keyof T {
    return key in object
}

/**
 * Get the result value of a settled promise.
 * @param settled The settled promise
 * @param throwOnError If true, re-throws the captured error if the promise was rejected
 */
export function getPromiseSettledResult<T>(settled: PromiseSettledResult<T>, throwOnError = false) {
    if (throwOnError && settled.status === 'rejected') {
        throw settled.reason
    }
    return settled.status === 'fulfilled' ? settled.value : null
}

export function pascalToKebabCase(str: string) {
    return str
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase()
}

interface CreateCancellableTaskOptions {
    abortController: AbortController
}

export function createCancellableTask<T>(task: () => Promise<T>, options?: Partial<CreateCancellableTaskOptions>) {
    const abortController = options?.abortController ?? new AbortController()

    const result = new Promise<T>((resolve, reject) => {
        const abortHandler = () => {
            reject(new DOMException('Aborted', 'AbortError'))
        }

        abortController.signal.addEventListener('abort', abortHandler)

        task()
            .then(result => {
                abortController.signal.removeEventListener('abort', abortHandler)
                resolve(result)
            })
            .catch(error => {
                abortController.signal.removeEventListener('abort', abortHandler)
                reject(error)
            })
    })

    return {
        result: result,
        cancel: () => abortController.abort(),
    }
}
